import React, {useEffect} from "react";
    
class HubspotContactFormPartner extends React.Component {
    render() {
        if (typeof window !== `undefined`) {
            const script = document.createElement('script');
            script.src='https://js-eu1.hsforms.net/forms/v2.js';
            document.body.appendChild(script);

            script.addEventListener('load', () => {
                // @TS-ignore
                if (window.hbspt) {
                    // @TS-ignore
                    window.hbspt.forms.create({
                        region: "eu1",
                        portalId: "25210143",
                        // formId: '63a4f934-62b0-4ff2-b126-9f97421729fb',
                        formId: this.props.id,
                        target: '#hubspotForm'
                    })
                }
            })
        }
        return (
            <div>
                <div id="hubspotForm"></div>
            </div>
        );
    }
}

export default HubspotContactFormPartner;

